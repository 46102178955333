import { createSearchStore, SearchModalInit } from '@saks/search-experience';

function fireOptimizelyQueryLengthEvent(event) {
  const query = event.target.getElementsByClassName('search-field')[0].value
  if (window['optimizely']) {
    window['optimizely'].push({
      type: "event",
      eventName: 'Search_query_length',
      tags: { queryLength: query.length, searchV2: false }
    })
  }
}

const setupSearch = () => {
  const { requestModalOpen, inputStream } = SearchModalInit(createSearchStore()) // SearchModalInit exposes Redux bound utilities for us
  const searchParams = new URLSearchParams(document.location.search)

  const onInput = ({ target }) => {
    requestModalOpen()
    inputStream(target.value) // feed customer input into controlled Redux state
  }

  const headerSearchForms = document.querySelectorAll('.page .search-form')
  headerSearchForms.forEach(form => {
    const input = form.querySelector('input[name="q"]')
    input.addEventListener('click', requestModalOpen)
    input.addEventListener('input', onInput)
    form.addEventListener('submit', fireOptimizelyQueryLengthEvent)

    if (searchParams.get('q')) {
      input.value = searchParams.get('q')
    }

    if (window.FABuild) { // case to take care of mobile app - otherwise we don't want a focus event
      input.addEventListener('focus', requestModalOpen, { once: true })
    }
  })

  const openModalTrigger = evt => {
    evt.preventDefault()
    requestModalOpen()
  }

  const searchButtons = document.querySelectorAll('.site-search .search-form button[name="search-button"]')
  searchButtons.forEach(searchButton => {
    searchButton.addEventListener('click', openModalTrigger)
  })

  if (!window.FABuild) {
    searchFormInput.removeEventListener('focus', handleSearchForm);
  }
}

// For web, only call the SearchService functions when interacting with search form
function handleSearchForm() {
  setupSearch();
}

if (!window.FABuild) {
  var searchFormInput = document.getElementsByClassName('search-field')[0];
  searchFormInput.addEventListener('focus', handleSearchForm);
  setupSearch();
}

$('.header-search-overlay.preview').remove();
